import React from "react";
import TodoList from "./todo/TodoList";
import "./checklist.css";

const Checklist = () => {
  return (
    <>
        <TodoList/>
    </>
  );
}

export default Checklist;;